"use strict";

var _excluded = ["giftCards"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var store = require('adyen/../../../store');
var _require = require('./renderGenericComponent'),
  initializeCheckout = _require.initializeCheckout;
var helpers = require('adyen/adyen_checkout/helpers');
function makePartialPayment(requestData) {
  var error;
  $.ajax({
    url: window.partialPaymentUrl,
    type: 'POST',
    data: JSON.stringify(requestData),
    contentType: 'application/json; charset=utf-8',
    async: false,
    success: function success(response) {
      if (response.error) {
        error = {
          error: true
        };
      } else {
        var giftCards = response.giftCards,
          rest = _objectWithoutProperties(response, _excluded);
        store.checkout.options.amount = rest.remainingAmount;
        store.adyenOrderData = rest.partialPaymentsOrder;
        store.partialPaymentsOrderObj = rest;
        sessionStorage.setItem('partialPaymentsObj', JSON.stringify(rest));
        // Neuhaus change
        // The payment selected is lost after the initializeCheckout is invoked
        sessionStorage.setItem('paymentSelected', $('.paymentMethod input:checked').val());
        ///////
        store.addedGiftCards = giftCards;
        helpers.setOrderFormData(response);
        initializeCheckout();
      }
    }
  }).fail(function () {});
  return error;
}
module.exports = {
  makePartialPayment: makePartialPayment
};