'use strict';
var base = require('base/checkout/summary');

module.exports = Object.create(base);

module.exports.updateOrderProductSummaryInformation = function (order) {
    if (order.productSummaryHtml) {
        $('.order-product-summary').replaceWith(order.productSummaryHtml);
    }
};
