'use strict';

const base = require('base/checkout/billing');

var addressHelpers = require('./address');
var countrySelectHelper = require('../components/localePreferences');

addressHelpers.init('billing');

/**
 * updates layout billing address form
 * @param {Object} addressObject - the address object
 * @param {Object} targetedForm - the form to set the layout off
 */
function setLayout(addressObject, targetedForm) {
    var form = targetedForm || false;
    var isCompany = !!((addressObject.companyName || addressObject.vat));
    isCompany = addressObject.isCompany ? addressObject.isCompany : isCompany;

    var checkbox = form ? $('.js-billing-company-checkbox', form) : $('.js-billing-company-checkbox');

    // set checkbox
    checkbox.prop('checked', isCompany);

    // show fields
    const companyInfo = checkbox.closest('.js-company-info');
    companyInfo.find('.js-company-fields').toggleClass('d-none', !isCompany);
    $('.js-company-po').toggleClass('d-none', !isCompany);

    // set required
    companyInfo.find('.js-company-name').prop('required', isCompany);
    companyInfo.find('.js-company-name').attr('aria-required', isCompany.toString());

    companyInfo.find('.js-company-vat').prop('required', isCompany);
    companyInfo.find('.js-company-vat').attr('aria-required', isCompany.toString());
}


/**
 * updates the billing address selector within billing forms
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateBillingAddressSelector(order, customer) {
    var shippings = order.shipping;

    var form = $('form[name$=billing]')[0];
    var $billingAddressSelector = $('.addressSelector', form);
    var hasSelectedAddress = false;

    if ($billingAddressSelector && $billingAddressSelector.length === 1) {
        $billingAddressSelector.empty();
        // Add New Address option
        $billingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            null,
            true,
            order,
            { type: 'billing' }));

        // Separator - Shipments in Progress de not place for click and collect
        if (!order.shipping.fromStoreId) {
            $billingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.shippingAddresses, false, order, {
                    // className: 'multi-shipping',
                    type: 'billing'
                }
            ));
        }

        // Shipping Address In progress
        shippings.forEach(function (aShipping) {
            hasSelectedAddress = order.billing.matchingAddressId === aShipping.UUID;
            if (!aShipping.selectedShippingMethod.storePickupEnabled && !aShipping.selectedShippingMethod.onlineGiftCard) {
                $billingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress(aShipping, false, order,
                        {
                            // className: 'multi-shipping',
                            type: 'billing'
                        }
                    )
                );
            }
        });

        if (customer.addresses && customer.addresses.length > 0) {
            $billingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.accountAddresses, false, order));
            customer.addresses.forEach(function (address) {
                hasSelectedAddress = order.billing.matchingAddressId === address.ID;
                // Customer Address option
                $billingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress({
                        UUID: 'ab_' + address.ID,
                        shippingAddress: address
                    }, false, order, { type: 'billing' })
                );
            });
        }
    }

    if (hasSelectedAddress
        || (!order.billing.matchingAddressId && order.billing.billingAddress.address)) {
        // show
        $(form).attr('data-address-mode', 'edit');
    } else {
        $(form).attr('data-address-mode', 'new');
    }

    $billingAddressSelector.show();
}


/**
 * Updates the payment information in checkout, based on the supplied order model
 * @param {Object} order - checkout model to use as basis of new truth
 */
function updatePaymentInformation(order) {
    // update payment details
    var $paymentSummary = $('.payment-details');
    var htmlToAppend = '';

    if (order.billing.payment && order.billing.payment.selectedPaymentInstruments
        && order.billing.payment.selectedPaymentInstruments.length > 0) {
        if (order.billing.payment.selectedPaymentInstruments[0].paymentMethod === 'CREDIT_CARD') {
            htmlToAppend += '<span>' + order.resources.cardType + ' '
                + order.billing.payment.selectedPaymentInstruments[0].type
                + '</span>';

            if (order.billing.payment.selectedPaymentInstruments[0].maskedCreditCardNumber) {
                htmlToAppend += '<div>'
                    + order.billing.payment.selectedPaymentInstruments[0].maskedCreditCardNumber
                    + '</div>';
            }
            if (order.billing.payment.selectedPaymentInstruments[0].expirationMonth && order.billing.payment.selectedPaymentInstruments[0].expirationYear) {
                htmlToAppend += '<div><span>'
                + order.resources.cardEnding + ' '
                + order.billing.payment.selectedPaymentInstruments[0].expirationMonth
                + '/' + order.billing.payment.selectedPaymentInstruments[0].expirationYear
                + '</span></div>';
            }
        } else if (order.billing.payment.selectedPaymentInstruments[0].paymentMethod === 'AdyenComponent') {
            htmlToAppend += '<div><span>'
                + order.billing.payment.selectedPaymentInstruments[0].selectedAdyenPM
                + '</span></div>';

            if (typeof order.billing.payment.selectedPaymentInstruments[0].selectedIssuerName !== 'undefined') {
                htmlToAppend += '<div><span>'
                    + order.billing.payment.selectedPaymentInstruments[0].selectedIssuerName
                    + '</span></div>';
            }
        }
    }

    $paymentSummary.empty().append(htmlToAppend);
}


/**
 * updates the billing address form values within payment forms
 * @param {Object} order - the order model
 */
function updateBillingAddressFormValues(order) {
    var billing = order.billing;
    if (!billing.billingAddress || !billing.billingAddress.address) return;

    var form = $('form[name=dwfrm_billing]');
    if (!form) return;

    base.methods.updateBillingAddressFormValues(order);

    setLayout(billing.billingAddress.address, form);

    $('input[name$=_companyName]', form).val(billing.billingAddress.address.companyName);
    $('input[name$=_vat]', form).val(billing.billingAddress.address.vat);
}


/**
 * Call base method and then enable/disable the 'Use shipping address as billing address' checkbox accordingly.
 *
 * @param {Object} order - checkout model to use as basis of new truth
 * @param {Object} customer - customer model to use as basis of new truth
 * @param {Object} [options] - options
 */
function updateBillingInformation(order, customer) {
    updateBillingAddressSelector(order, customer);
    // update billing address form
    updateBillingAddressFormValues(order);

    // update billing address summary
    addressHelpers.methods.populateAddressSummary('.billing .address-summary',
        order.billing.billingAddress.address);

    if (order.billing.billingAddress.address) {
        $('.js-poNrSummary').toggleClass('d-none', !order.billing.billingAddress.address.poNumber);
        $('.js-poNrSummary .poNumber').text(order.billing.billingAddress.address.poNumber || '');

        $('.js-voucherFromSummary').toggleClass('d-none', !order.billing.billingAddress.address.evoucherSender);
        $('.js-voucherFromSummary .poNumber').text(order.billing.billingAddress.address.evoucherSender || '');
    }

    // update billing parts of order summary
    $('.order-summary-email').text(order.orderEmail);

    if (order.billing.billingAddress.address) {
        $('.order-summary-phone').text(order.billing.billingAddress.address.phone);
    }

    $('.payment-form .billing-address-block .btn-show-details').trigger('click'); // Manually open the form

    // const $billingAddressCheckbox = $('#shippingAddressUseAsBillingAddress');
    // const $addressSelector = $billingAddressCheckbox.closest('.billing-address-block').find('#billing-address-collapse');

    // const billingAddressSameAsShipping = order.billingAddressSameAsShipping && !order.usingMultiShipping;
    // $billingAddressCheckbox.prop('checked', billingAddressSameAsShipping);
    // $addressSelector.toggleClass('show', !billingAddressSameAsShipping);
}

module.exports = $.extend({}, base, {
    methods: {
        updateBillingAddressSelector: updateBillingAddressSelector,
        updateBillingAddressFormValues: updateBillingAddressFormValues,
        clearBillingAddressFormValues: base.methods.clearBillingAddressFormValues,
        updateBillingInformation: updateBillingInformation,
        updatePaymentInformation: updatePaymentInformation,
        clearCreditCardForm: base.methods.clearCreditCardForm
    },

    selectBillingAddress: base.selectBillingAddress,

    onSelectBillingAddress: function () {
        $('.payment-form .addressSelector').on('change', function () {
            var form = $(this).parents('form')[0];
            var selectedOption = $('option:selected', this);

            // Copy fields
            var attrs = selectedOption.data();

            Object.keys(attrs).forEach(function (attr) {
                if (attr === 'countryCode') {
                    $('[name$=_billingCountry]', form).val(attrs[attr]);
                }
            });

            setLayout(attrs, form);
        });
    },

    handlePaymentMethodSelection: () => {
        $(document).on('change', '.paymentMethod .custom-control-input', function () {
            if ($(this).prop('checked')) {
                const methodId = $('[data-method-id]').attr('data-method-id');

                // Make the tab active, although we are not really using tabs anymore, but logic in checkout.js needs it (and it's cleaner to do this than to copy the whole file just to change that bit)
                const $paymentInfoContainer = $('.payment-information');
                $('.tab-pane', $paymentInfoContainer).removeClass('active');
                $(this).closest('.tab-pane').addClass('active');

                $paymentInfoContainer.data('payment-method-id', methodId);
                $('#selectedPaymentOption').val(methodId);

                // Make all Adyen methods and Credit Card work like radio buttons
                if ($(this).is('#creditCardPaymentOption')) {
                    $('.adyen-content .custom-control-input').prop('checked', false);
                } else {
                    $('#creditCardPaymentOption').prop('checked', false).trigger('change');
                }
            }
        });

        $(document).on('change', '.paymentMethod .custom-control-input#creditCardPaymentOption', function () {
            $('#credit-card-block-collapse').collapse($(this).prop('checked') ? 'show' : 'hide');
        });
    },

    onBillingAddressCheckboxChange: () => {
        const $shipmentAddressSelector = $('#shipmentSelector-default');
        const $billingAddressSelector = $('#billingAddressSelector');
        const $billingAddressCheckbox = $('#shippingAddressUseAsBillingAddress');

        $billingAddressCheckbox.change(() => {
            if ($billingAddressCheckbox.prop('checked')) {
                // When 'Use shipping address as billing address' checkbox is checked,
                // we reset the selector to use the same value as the shipping address selector
                const selectedShippingAddress = $shipmentAddressSelector.val();
                if ($billingAddressSelector.find(`option[value="${selectedShippingAddress}"]`).length) {
                    $billingAddressSelector.val(selectedShippingAddress);
                } else {
                    // Sometimes there is a "ab_" prefix in the value, but is the same ID
                    $billingAddressSelector.val(selectedShippingAddress.replace(/^ab_/, ''));
                }
                $billingAddressSelector.trigger('change');
            }
        });
    },

    onBillingCompanyClick: function () {
        $('.js-billing-company-checkbox').change(function () {
            const companyInfo = $(this).closest('.js-company-info');
            companyInfo.find('.js-company-fields').toggleClass('d-none', !this.checked);
            $('.js-company-po').toggleClass('d-none', !this.checked);

            companyInfo.find('.js-company-name').prop('required', this.checked);
            companyInfo.find('.js-company-name').attr('aria-required', this.checked.toString());
            companyInfo.find('.js-company-vat').prop('required', this.checked);
            companyInfo.find('.js-company-vat').attr('aria-required', this.checked.toString());
        });
    },

    handleAlphabeticalSortCountry: function () {
        const $countrySelect = $('select[name$="addressFields_billingCountry"]');
        $countrySelect.each(function () {
            countrySelectHelper.handleAlphabeticalSort($(this));
        });
    }
});
