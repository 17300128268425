'use strict';

var base = require('base/checkout/address');

/**
 * returns a formed <option /> element
 * @param {Object} shipping - the shipping object (shipment model)
 * @param {boolean} selected - current shipping is selected (for PLI)
 * @param {order} order - the Order model
 * @param {Object} [options] - options
 * @returns {Object} - the jQuery / DOMElement
 */
function optionValueForAddress(shipping, selected, order, options) {
    var optionEl = base.methods.optionValueForAddress(shipping, selected, order, options);

    var safeOptions = options || {};
    var isBilling = safeOptions.type && safeOptions.type === 'billing';
    var isNew = !shipping;
    var safeShipping = shipping || {};
    var shippingAddress = safeShipping.shippingAddress || {};

    if (isBilling && isNew && !order.billing.matchingAddressId) {
        shippingAddress = order.billing.billingAddress.address || {};
    }

    optionEl.attr('data-is-company', (shippingAddress.isCompany || !!((shippingAddress.companyName || shippingAddress.vat))).toString());
    optionEl.attr('data-company-name', shippingAddress.companyName || '');
    optionEl.attr('data-vat', shippingAddress.vat || '');
    optionEl.attr('data-suite', shippingAddress.suite || '');

    optionEl.attr('data-gift-sender', shippingAddress.giftSender || '');

    return optionEl;
}

/**
 * returns address properties from a UI form
 * @param {Form} form - the Form element
 * @returns {Object} - a JSON object with all values
 */
function getAddressFieldsFromUI(form) {
    var address = $.extend(base.methods.getAddressFieldsFromUI(form), {
        isCompany: $('input[name$=_isCompany]', form).prop('checked'),
        companyName: $('input[name$=_companyName]', form).val(),
        vat: $('input[name$=_vat]', form).val(),
        suite: $('input[name$=_suite]', form).val(),

        isGift: $('input[name$=_isGift]', form).prop('checked'),
        giftSender: $('input[name$=_giftSender]', form).val(),
        giftMessage: $('textarea[name$=_giftMessage]', form).val()

    });
    return address;
}
/**
 * this will update any fields with the required prop where needed depending on country
 * @param {Object} element the element field that has been changed
 */
function updateLocaleRequiredFields(element) {
    let locales = $('#RequireHouseNrLocales').text().split(' ');
    let fieldId = element.attr('id') === 'country' ? '' : element.attr('id').split('Country')[1];
    let checkoutAddressStep = element.attr('id') === 'country' ? 'shipping' : element.attr('id').split('Country')[0];
    $('#' + checkoutAddressStep + 'AddressTwo' + fieldId).prop('required', locales.includes(element.val()));
}
/**
 * init
 * @param {string} addressStep the step in which the address fields are used => billing, shipping, (registration has no step name => '')
 */
function init(addressStep) {
    let countryString = addressStep === '' ? 'country' : 'Country';
    let elementClass = addressStep + countryString;
    $('.' + elementClass).on('change', function () {
        updateLocaleRequiredFields($(this));
    });
    $('.' + elementClass).each(function () {
        updateLocaleRequiredFields($(this));
    });
}


module.exports = {
    methods: {
        populateAddressSummary: base.methods.populateAddressSummary,
        optionValueForAddress: optionValueForAddress,
        getAddressFieldsFromUI: getAddressFieldsFromUI
    },
    showDetails: base.showDetails,
    addNewAddress: base.addNewAddress,
    init: init
};
