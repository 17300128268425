'use strict';
var giftCard = require('core/checkout/giftCard');
var processInclude = require('base/util');
var checkout = require('./checkout/checkout');
var adyenCheckout = require('./adyenCheckout');
var billing = require('core/checkout/billing');

let initCheckout = () => {
     // eslint-disable-line
    var name = 'paymentError';
    var error = new RegExp("[?&]".concat(encodeURIComponent(name), "=([^&]*)")).exec(window.location.search);
    var paymentStage = new RegExp('[?&]stage=payment([^&]*)').exec(window.location.search);
    if (error || paymentStage) {
        if (error) {
            $('.error-message').show();
            $('.error-message-text').text(decodeURIComponent(error[1]));
        }
        adyenCheckout.renderGenericComponent();
    }
};


let checkValues = () => {
    var restrictedPayments = window.adyenPaymentMethodsRestrictedWithGiftcards;
    var paymentMethodsList = restrictedPayments.split(',');
    if (restrictedPayments) {
        paymentMethodsList.forEach(paymentValue => {
            var $component = $('#component_' + paymentValue);
            var $parent = $component.parent();
            var $error = $parent.find('#tempError');
            if ($('#giftCardsCancelContainer:visible').length > 0) {
                if ($('#rb_' + paymentValue).is(':checked')) {
                    $('.submit-payment').attr('disabled', true);
                    if ($error.length === 0) {
                        $parent.append("<p class='text-danger' id='tempError'>" + window.paymentCombinedWithGiftcardsRestrictedMsg + '</p>');
                    }
                    $component.hide();
                    $error.show();
                } else {
                    $error.hide();
                }
            } else if ($('#rb_' + paymentValue).is(':checked')) {
                $component.show();
                $error.hide();
            }
        });
    }
};

$(document).ready(function () {
    processInclude(checkout);
    processInclude(billing);
    giftCard.init();
    initCheckout();
    $('#selectedPaymentOption').val($('.payment-options .nav-item .active').parent().attr('data-method-id'));
});
var validRegex = new RegExp(/^[\w.%+-]+@[\w.-]+\.[\w]{2,20}$/);
$('#email').on('input', function () {
    if (validRegex.test($(this).val())) {
        $('.adyen-checkout__paypal').css({ opacity: '1', 'pointer-events': 'all' });
    } else {
        $('.adyen-checkout__paypal').css({ opacity: '0.5', 'pointer-events': 'none' });
    }
});

$('.payment-options .nav-link').click(function () {
    $('#selectedPaymentOption').val($(this).parent().attr('data-method-id'));
});

$(document).on('change removeGitCardEvent', '.paymentMethod, #giftCardAddButton', function () {
    checkValues();
});

setInterval(() => {
    if (!validRegex.test($('#email').val())) {
        $('.adyen-checkout__paypal').css({ opacity: '0.5', 'pointer-events': 'none' });
    }
}, 50);
