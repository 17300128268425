"use strict";

var adyenCheckout = require('../adyenCheckout');
var addressHelpers = require('core/checkout/address');
var shippingHelpers = require('core/checkout/shipping');
var billingHelpers = require('core/checkout/billing');
var summaryHelpers = require('core/checkout/summary');
var formHelpers = require('base/checkout/formErrors');
var scrollAnimate = require('base/components/scrollAnimate');

// replaced from sfra
/**
 * Create the jQuery Checkout Plugin.
 *
 * This jQuery plugin will be registered on the dom element in checkout.isml with the
 * id of "checkout-main".
 *
 * The checkout plugin will handle the different state the user interface is in as the user
 * progresses through the varying forms such as shipping and payment.
 *
 * Billing info and payment info are used a bit synonymously in this code.
 *
 */
(function ($) {
    $.fn.checkout = function () { // eslint-disable-line
        var plugin = this;

        //
        // Collect form data from user input
        //
        var formData = {
            // Shipping Address
            shipping: {},

            // Billing Address
            billing: {},

            // Payment
            payment: {},

            // Gift Codes
            giftCode: {}
        };

        //
        // The different states/stages of checkout
        //
        var checkoutStages = [
            'shipping',
            'payment',
            'placeOrder',
            'submitted'
        ];

        /**
         * Updates the URL to determine stage
         * @param {number} currentStage - The current stage the user is currently on in the checkout
         */
        function updateUrl(currentStage) {
            history.pushState(
                checkoutStages[currentStage],
                document.title,
                location.pathname
                + '?stage='
                + checkoutStages[currentStage]
                + '#'
                + checkoutStages[currentStage]
            );
        }

        //
        // Local member methods of the Checkout plugin
        //
        var members = {

            // initialize the currentStage variable for the first time
            currentStage: 0,

            /**
             * Set or update the checkout stage (AKA the shipping, billing, payment, etc... steps)
             * @returns {Object} a promise
             */
            updateStage: function () {
                var stage = checkoutStages[members.currentStage];
                var defer = $.Deferred(); // eslint-disable-line
                if (stage === 'shipping') {
                    //
                    // Clear Previous Errors
                    //
                    formHelpers.clearPreviousErrors('.shipping-form');

                    //
                    // Submit the Shipping Address Form
                    //
                    var isMultiShip = $('#checkout-main').hasClass('multi-ship');
                    var formSelector = isMultiShip ?
                        '.multi-shipping .active form' : '.single-shipping .shipping-form';
                    var form = $(formSelector);

                    if (isMultiShip && form.length === 0) {
                        // disable the next:Payment button here
                        $('body').trigger('checkout:disableButton', '.next-step-button button');
                        // in case the multi ship form is already submitted
                        var url = $('#checkout-main').attr('data-checkout-get-url');
                        $.ajax({
                            url: url,
                            method: 'GET',
                            success: function (data) {
                                // enable the next:Payment button here
                                $('body').trigger('checkout:enableButton', '.next-step-button button');
                                if (!data.error) {
                                    $('body').trigger('checkout:updateCheckoutView',
                                        { order: data.order, customer: data.customer });

                                    // if (data.order.brexitAdjustment) {
                                    //     $('.brexit-collection-billing').removeClass('d-none');
                                    //     $('.js-payment-collection-container').addClass('d-none');
                                    //     $('.btn.submit-payment').addClass('d-none');

                                    //     data.order.shipping.forEach(function (shippingModel) {
                                    //         var billingShipmentBrexitmsgSelector = '.js-brexitnotification-billing-shipment-' + shippingModel.UUID;
                                    //         $(billingShipmentBrexitmsgSelector).toggleClass('d-none', !shippingModel.brexitAdjustment);
                                    //     });
                                    // } else {
                                        // $('.brexit-collection-billing').addClass('d-none');
                                    $('.js-payment-collection-container').removeClass('d-none');
                                    $('.btn.submit-payment').removeClass('d-none');
                                    // }

                                    defer.resolve();
                                } else if (data.message && $('.shipping-error .alert-danger').length < 1) {
                                    var errorMsg = data.message;
                                    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                                        'fade show" role="alert">' +
                                        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                                        '<span aria-hidden="true">&times;</span>' +
                                        '</button>' + errorMsg + '</div>';
                                    $('.shipping-error').append(errorHtml);
                                    scrollAnimate($('.shipping-error'));
                                    defer.reject();
                                } else if (data.redirectUrl) {
                                    window.location.href = data.redirectUrl;
                                }
                            },
                            error: function () {
                                // enable the next:Payment button here
                                $('body').trigger('checkout:enableButton', '.next-step-button button');
                                // Server error submitting form
                                defer.reject();
                            }
                        });
                    } else {
                        // custom
                        var storePickupForm = $('#pickup-in-store-form');
                        var isStorePickup = $('.js-pickup').hasClass('js-active');
                        var shippingFormData = isStorePickup ? storePickupForm.serialize() : form.serialize();
                        $('body').trigger('checkout:serializeShipping', {
                            form: form,
                            data: shippingFormData,
                            callback: function (data) {
                                shippingFormData = data;
                            }
                        });

                        var singleShippingUrl;
                        if ($('#checkout-main').hasClass('js-online-gift-card-flow')) {
                            singleShippingUrl = $('#checkout-main').data('checkout-get-url');
                        } else {
                            singleShippingUrl = form.attr('action');
                        }

                        // disable the next:Payment button here
                        $('body').trigger('checkout:disableButton', '.next-step-button button');
                        $.ajax({
                            url: singleShippingUrl, // CheckoutShippingServices-SubmitShipping
                            type: 'post',
                            data: shippingFormData,
                            success: function (data) {
                                if (data.error) {
                                    if (data.fieldErrors.length) {
                                        data.fieldErrors.forEach(function (error) {
                                            if (Object.keys(error).length) {
                                                formHelpers.loadFormErrors('#pickup-in-store-form', error);
                                            }
                                        });
                                    }
                                }
                                // enable the next:Payment button here
                                $('body').trigger('checkout:enableButton', '.next-step-button button');
                                shippingHelpers.methods.shippingFormResponse(defer, data);
                            },
                            error: function (err) {
                                scrollAnimate($('.error-message'));

                                // enable the next:Payment button here
                                $('body').trigger('checkout:enableButton', '.next-step-button button');

                                if (err.responseJSON && err.responseJSON.redirectUrl) {
                                    window.location.href = err.responseJSON.redirectUrl;
                                }
                                // Server error submitting form
                                defer.reject(err.responseJSON);
                            }
                        });
                    }
                    return defer;
                } else if (stage === 'payment') {
                    //
                    // Submit the Billing Address Form
                    //

                    formHelpers.clearPreviousErrors('.payment-form');

                    var billingAddressForm = $('#dwfrm_billing .billing-address-block :input').serialize();

                    $('body').trigger('checkout:serializeBilling', {
                        form: $('#dwfrm_billing .billing-address-block'),
                        data: billingAddressForm,
                        callback: function (data) {
                            if (data) {
                                billingAddressForm = data;
                            }
                        }
                    });

                    var contactInfoForm = $('#dwfrm_billing .contact-info-block :input').serialize();

                    $('body').trigger('checkout:serializeBilling', {
                        form: $('#dwfrm_billing .contact-info-block'),
                        data: contactInfoForm,
                        callback: function (data) {
                            if (data) {
                                contactInfoForm = data;
                            }
                        }
                    });

                    var giftcardInfoForm = $('#dwfrm_billing .giftcard-info-block :input').serialize();

                    $('body').trigger('checkout:serializeBilling', {
                        form: $('#dwfrm_billing .contact-info-block'),
                        data: contactInfoForm,
                        callback: function (data) {
                            if (data) {
                                contactInfoForm = data;
                            }
                        }
                    });

                    var activeTabId = $('.tab-pane.active').attr('id');
                    var paymentInfoSelector = '#dwfrm_billing .' + activeTabId + ' .payment-form-fields :input';
                    var paymentInfoForm = $(paymentInfoSelector).serialize();

                    $('body').trigger('checkout:serializeBilling', {
                        form: $(paymentInfoSelector),
                        data: paymentInfoForm,
                        callback: function (data) {
                            if (data) {
                                paymentInfoForm = data;
                            }
                        }
                    });

                    var paymentForm = billingAddressForm + '&' + contactInfoForm + '&' + giftcardInfoForm + '&' + paymentInfoForm;

                    if ($('.data-checkout-stage').data('customer-type') === 'registered') {
                        // if payment method is credit card
                        if ($('.payment-information').data('payment-method-id') === 'CREDIT_CARD') {
                            if (!($('.payment-information').data('is-new-payment'))) {
                                var cvvCode = $('.saved-payment-instrument.' +
                                    'selected-payment .saved-payment-security-code').val();

                                if (cvvCode === '') {
                                    var cvvElement = $('.saved-payment-instrument.' +
                                        'selected-payment ' +
                                        '.form-control');
                                    cvvElement.addClass('is-invalid');
                                    scrollAnimate(cvvElement);
                                    defer.reject();
                                    return defer;
                                }

                                var $savedPaymentInstrument = $('.saved-payment-instrument' +
                                    '.selected-payment'
                                );

                                paymentForm += '&storedPaymentUUID=' +
                                    $savedPaymentInstrument.data('uuid');

                                paymentForm += '&securityCode=' + cvvCode;
                            }
                        }
                    }
                    // disable the next:Place Order button here
                    $('body').trigger('checkout:disableButton', '.next-step-button button');
                    $.ajax({
                        url: $('#dwfrm_billing').attr('action'), // CheckoutServices-SubmitPayment
                        method: 'POST',
                        data: paymentForm,
                        success: function (data) {
                            // enable the next:Place Order button here
                            $('body').trigger('checkout:enableButton', '.next-step-button button');
                            // look for field validation errors
                            if (data.error) {
                                if (data.fieldErrors.length) {
                                    data.fieldErrors.forEach(function (error) {
                                        if (Object.keys(error).length) {
                                            formHelpers.loadFormErrors('.payment-form', error);
                                        }
                                    });

                                    // disable billing same as shipping when needed to show error
                                    var $billingAddressSameAsShipping = $('input[name$="_shippingAddressUseAsBillingAddress"]');
                                    if ($billingAddressSameAsShipping.is(':checked')) {
                                        $billingAddressSameAsShipping.prop('checked', false);

                                        var $addressSelector = $billingAddressSameAsShipping.closest('.billing-address-block').find('#billing-address-collapse');
                                        $addressSelector.addClass('show');
                                    }

                                    // open form upon error
                                    $('.payment-form').find('.btn-show-details').trigger('click');
                                }

                                if (data.serverErrors.length) {
                                    data.serverErrors.forEach(function (error) {
                                        $('.error-message').show();
                                        $('.error-message-text').text(error);
                                        scrollAnimate($('.error-message'));
                                    });
                                }

                                if (data.cartError) {
                                    window.location.href = data.redirectUrl;
                                }

                                defer.reject();
                            } else {
                                //
                                // Populate the Address Summary
                                //
                                $('body').trigger('checkout:updateCheckoutView',
                                    { order: data.order, customer: data.customer });

                                if (data.renderedPaymentInstruments) {
                                    $('.stored-payments').empty().html(
                                        data.renderedPaymentInstruments
                                    );
                                }

                                if (data.customer.registeredUser
                                    && data.customer.customerPaymentInstruments.length
                                ) {
                                    $('.cancel-new-payment').removeClass('checkout-hidden');
                                }

                                scrollAnimate();
                                defer.resolve(data);
                            }
                        },
                        error: function (err) {
                            // enable the next:Place Order button here
                            $('body').trigger('checkout:enableButton', '.next-step-button button');
                            if (err.responseJSON && err.responseJSON.redirectUrl) {
                                window.location.href = err.responseJSON.redirectUrl;
                            }
                        }
                    });

                    return defer;
                } else if (stage === 'placeOrder') {
                    // disable the placeOrder button here
                    $('body').trigger('checkout:disableButton', '.next-step-button button');
                    $.ajax({
                        url: $('.place-order').data('action'),
                        method: 'POST',
                        success: function (data) {
                            // enable the placeOrder button here
                            $('body').trigger('checkout:enableButton', '.next-step-button button');
                            if (data.error) {
                                if (data.cartError) {
                                    window.location.href = data.redirectUrl;
                                    defer.reject();
                                } else {
                                    // go to appropriate stage and display error message
                                    defer.reject(data);
                                }
                            } else if (data.adyenAction) {
                                window.orderToken = data.orderToken;
                                adyenCheckout.actionHandler(data.adyenAction);
                                // ### Custom Adyen cartridge end ###
                            } else {
                                var continueUrl = data.continueUrl;
                                var urlParams = {
                                    ID: data.orderID,
                                    token: data.orderToken
                                };

                                continueUrl += (continueUrl.indexOf('?') !== -1 ? '&' : '?') +
                                    Object.keys(urlParams).map(function (key) {
                                        return key + '=' + encodeURIComponent(urlParams[key]);
                                    }).join('&');

                                window.location.href = continueUrl;
                                defer.resolve(data);
                            }
                        },
                        error: function () {
                            // enable the placeOrder button here
                            $('body').trigger('checkout:enableButton', $('.next-step-button button'));
                        }
                    });

                    return defer;
                }
                var p = $('<div>').promise(); // eslint-disable-line
                setTimeout(function () {
                    p.done(); // eslint-disable-line
                }, 500);
                return p; // eslint-disable-line
            },

            /**
             * Initialize the checkout stage.
             *
             * TODO: update this to allow stage to be set from server?
             */
            initialize: function () {
                // set the initial state of checkout
                members.currentStage = checkoutStages
                    .indexOf($('.data-checkout-stage').data('checkout-stage'));
                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);

                //
                // Handle Payment option selection
                //
                $('input[name$="paymentMethod"]', plugin).on('change', function () {
                    $('.credit-card-form').toggle($(this).val() === 'CREDIT_CARD');
                });

                //
                // Handle Next State button click
                //
                $(plugin).on('click', '.next-step-button button', function (e) {
                    e.stopPropagation();
                    members.nextStage();
                });

                //
                // Handle Edit buttons on shipping and payment summary cards
                //
                $('.shipping-summary .edit-button', plugin).on('click', function () {
                    if (!$('#checkout-main').hasClass('multi-ship')) {
                        $('body').trigger('shipping:selectSingleShipping');
                    }

                    members.gotoStage('shipping');
                });

                $('.payment-summary .edit-button', plugin).on('click', function () {
                    members.gotoStage('payment');
                });

                //
                // remember stage (e.g. shipping)
                //
                updateUrl(members.currentStage);

                //
                // Listen for foward/back button press and move to correct checkout-stage
                //
                $(window).on('popstate', function (e) {
                    //
                    // Back button when event state less than current state in ordered
                    // checkoutStages array.
                    //
                    if (e.state === null ||
                        checkoutStages.indexOf(e.state) < members.currentStage) {
                        members.handlePrevStage(false);
                    } else if (checkoutStages.indexOf(e.state) > members.currentStage) {
                        // Forward button  pressed
                        members.handleNextStage(false);
                    }
                });

                //
                // Set the form data
                //
                plugin.data('formData', formData);
            },

            /**
             * The next checkout state step updates the css for showing correct buttons etc...
             */
            nextStage: function () {
                var promise = members.updateStage();

                promise.done(function () {
                    // Update UI with new stage
                    members.handleNextStage(true);
                });

                promise.fail(function (data) {
                    // show errors
                    if (data) {
                        if (data.errorStage) {
                            members.gotoStage(data.errorStage.stage);

                            if (data.errorStage.step === 'billingAddress') {
                                var $billingAddressSameAsShipping = $(
                                    'input[name$="_shippingAddressUseAsBillingAddress"]'
                                );
                                if ($billingAddressSameAsShipping.is(':checked')) {
                                    $billingAddressSameAsShipping.prop('checked', false);
                                }
                            }
                        }

                        if (data.errorMessage) {
                            $('.error-message').show();
                            $('.error-message-text').text(data.errorMessage);
                        }
                    }
                });
            },

            /**
             * The next checkout state step updates the css for showing correct buttons etc...
             *
             * @param {boolean} bPushState - boolean when true pushes state using the history api.
             */
            handleNextStage: function (bPushState) {
                if (members.currentStage < checkoutStages.length - 1) {
                    // move stage forward
                    members.currentStage++;

                    //
                    // show new stage in url (e.g.payment)
                    //
                    if (bPushState) {
                        updateUrl(members.currentStage);
                    }
                }

                // Set the next stage on the DOM
                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);
            },

            /**
             * Previous State
             */
            handlePrevStage: function () {
                if (members.currentStage > 0) {
                    // move state back
                    members.currentStage--;
                    updateUrl(members.currentStage);
                }

                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);
            },

            /**
             * Use window history to go to a checkout stage
             * @param {string} stageName - the checkout state to goto
             */
            gotoStage: function (stageName) {
                members.currentStage = checkoutStages.indexOf(stageName);
                updateUrl(members.currentStage);
                $(plugin).attr('data-checkout-stage', checkoutStages[members.currentStage]);
            }
        };

        //
        // Initialize the checkout
        //
        members.initialize();

        return this;
    };
}(jQuery));

var exports = {
    initialize: function () {
        $('#checkout-main').checkout();
    },

    refreshCustomSelects: () => {
        const refresh = () => setTimeout(() => $(document).trigger('refresh:custom-select'), 0);
        $('.addressSelector').on('change', () => {
            refresh();
            // shippingHelpers.methods.updateShippingMethodList($('.multi-shipping .shipping-form'));
        });
        $('body').on('checkout:updateCheckoutView shipping:updateShippingAddressFormValues shipping:clearShippingForms checkout:clearBillingForm', refresh);
    },

    preselectCountry: () => {
        const defaultCountry = $('html').data('country');
        $('select[name$=_country]').each(function () {
            if (!this.value) {
                $(this).val(defaultCountry).trigger('change');
            }
        });
        $('body').on('shipping:updateShippingAddressFormValues shipping:clearShippingForms checkout:clearBillingForm', function () {
            $('select[name$=_country]').each(function () {
                if (!this.value) {
                    $(this).val(defaultCountry).trigger('change');
                }
            });
        });
    },

    updateCheckoutStep: function () {
        const target = $$('#checkout-main');
        if (target.dataset.checkoutStage === 'shipping') {
            // added extra trigger on first step for datalayer
            $(document).trigger('checkout:loadStage', target.dataset.checkoutStage);
        }

        const observer = new MutationObserver(() => {
            let currentStep = target.dataset.checkoutStage;
            $('.checkout-steps .step').removeClass('active').filter(`[data-step="${currentStep}"]`).addClass('active');
            $(document).trigger('checkout:loadStage', currentStep);
        });
        observer.observe(target, { attributes: true, attributeFilter: ['data-checkout-stage'] });
    },

    init: function () {
        // show shipping methods if address is already filled
        var stateVal = $('.shippingState').val();
        var countryVal = $('.shippingCountry').val();
        if (stateVal !== '' && countryVal) {
            var $shippingBlock = $('.shipping-method-block');
            if (!$shippingBlock.data('online-gift-card') && !$shippingBlock.data('brexit')) {
                $shippingBlock.removeClass('d-none');
            }
        } else {
            $('.js-checkout-shipping-discount, .js-checkout-shipping-cost, .js-checkout-grand-total').addClass('d-none');
            $('.js-merch-total, .js-tbd-shipping-cost').removeClass('d-none');
        }

        // if ($('.single-shipping .shipment-selector-block').data('brexit')) {
        //     $('.single-shipping .shipment-selector-block').find('.btn-show-details').trigger('click');
        // }
        // shippingHelpers.methods.updateShippingMethodList($('.single-shipping .shipping-form'));
    },

    initGiftCardCheckout: () => {
        var $shippingBlock = $('.shipping-method-block');
        if ($shippingBlock.data('online-gift-card')) {
            $('.shipping-method-list [data-giftcard=true]').trigger('click');
        }
    },

    initClickAndCollect: () => {
        let $pickup = $('.js-pickup');
        if (!$pickup.length) return;
        let $delivery = $('.js-delivery');
        let $shippingMethod = $('.js-shippingMethod');
        $('.js-delivery, .js-pickup').on('click', function () {
            if (!$(this).hasClass('js-active')) {
                $shippingMethod.toggleClass('js-active');
                $shippingMethod.toggleClass('btn-outline-primary');
                $shippingMethod.toggleClass('btn-primary');
                $delivery.find('.icon').toggleClass('icon-store-white');
                $delivery.find('.icon').toggleClass('icon-store');
                $pickup.find('.icon').toggleClass('icon-location');
                $pickup.find('.icon').toggleClass('icon-location-white');
                $('.js-shipping-to-customer').toggleClass('d-none');
                $('.pickupInStore').toggleClass('d-none');
            }

            // handles activate correct shipping method
            if ($(this).hasClass('js-pickup')) {
                $('.shipping-method-list [data-pickup=true]').trigger('click');
                $('.submit-shipping').removeClass('d-none');
            } else {
                $('.shipping-method-list').find('.form-check-input.custom-control-input:visible').first().trigger('click');

                shippingHelpers.methods.updateShippingMethodList($('.single-shipping .shipping-form'));

                // if (!$('.single-shipping .brexit-collection-shipping').hasClass('d-none')) {
                //     $('.submit-shipping').addClass('d-none');
                // }
            }
        });
        $('.js-loadMoreShops').on('click', function () {
            $('.js-storeResultWrapper').removeClass('d-none');
            $(this).addClass('d-none');
        });
        $('body').on('stores:loaded', function () {
            $('.js-loadMoreShops').toggleClass('d-none', $('.js-storeResultWrapper').length < 3);
        });
        if ($('.shipping-method-list [data-pickup=true]').prop('checked')) {
            $pickup.trigger('click');
        }
    },

    updateCheckoutView: () => {
        $('body').on('checkout:updateCheckoutView', function (e, data) {
            shippingHelpers.methods.updateMultiShipInformation(data.order);
            summaryHelpers.updateTotals(data.order.totals);
            data.order.shipping.forEach(function (shipping) {
                shippingHelpers.methods.updateShippingInformation(shipping, data.order, data.customer, data.options);
            });
            var currentStage = location.search.substring( // eslint-disable-line no-restricted-globals
                location.search.indexOf('=') + 1 // eslint-disable-line no-restricted-globals
            );

            if (currentStage === 'shipping') {
                adyenCheckout.renderGenericComponent();
            }

            billingHelpers.methods.updateBillingInformation(data.order, data.customer, data.options);
            billingHelpers.methods.updatePaymentInformation(data.order, data.options);
            summaryHelpers.updateOrderProductSummaryInformation(data.order, data.options);
        });
    },

    disableButton: function () {
        $('body').on('checkout:disableButton', function (e, button) {
            $(button).prop('disabled', true);
        });
    },

    enableButton: function () {
        $('body').on('checkout:enableButton', function (e, button) {
            $(button).prop('disabled', false);
        });
    }
};


[shippingHelpers, addressHelpers, billingHelpers].forEach(function (library) {
    Object.keys(library).forEach(function (item) {
        if (typeof library[item] === 'object') {
            exports[item] = $.extend({}, exports[item], library[item]);
        } else {
            exports[item] = library[item];
        }
    });
});

module.exports = exports;
